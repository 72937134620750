$(function () {
  // Open modal
  $(document).on('click', '[data-overlay-image]', function (e) {
    e.preventDefault();

    const $this = $(this)
    const $body = $('body')
    const srcImage = $this.data('overlay-image')

    if (!srcImage.length) return;

    const modalImage = function (src) {
      return $(`
        <div class="js-bg-overlay-image modal">
          <span class="close">&times;</span>
          <div class="slides" style="width: 60%; height: 80%; margin: 0 auto">
            <img src="${src}" style="width:100%; height: 100%; object-fit: contain">
          </div>
        </div>
      `)
    }

    $('.js-bg-overlay-image').remove()
    let $modal = modalImage(srcImage)
    $body.append($modal);
    $('.js-bg-overlay-image').fadeIn('fast')
  });


  // Close modal
  $(document).on('click', '.js-bg-overlay-image .close', function () {
    closeOverModalImage();
  });

  $(document).on('keyup', function (e) {
    if (!$('.js-bg-overlay-image').length) return;

    if (e.key === "Escape") { // escape key maps to keycode `27`
      closeOverModalImage()
    }
  });

  function closeOverModalImage() {
    $('.js-bg-overlay-image').fadeOut(function () { $(this).remove() });
  }
});
