// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
require.context("../images", true, /\.(png|jpg|jpeg|svg|ico)$/)

import jquery from "jquery"
window.$ = window.jquery = jquery

import "@fortawesome/fontawesome-free/css/all.css";
import 'arctic_admin'
import "../stylesheets/active_admin";
import "@activeadmin/activeadmin";
import "activeadmin_addons";
import "./components/sidebar";
import "./components/charts/bar_line";
import "./components/flatpickr";
import "./components/paginate_link";
import "./components/checkall";
import "./components/belongs_to_menu";
import "./components/overlay_image";
import "./pages";
