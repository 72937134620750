window.previewImage = function (event, previewElement) {
  if (previewElement == null) {
    previewElement = '';
  }
  previewElement || (previewElement = document.getElementById('js-preview-image'));

  let reader = new FileReader;
  reader.onload = function () {
    $(previewElement).prop('src', reader.result);
  };

  let file = (event.target || event).files[0];
  reader.readAsDataURL(file);
};
