$(function () {
  $('.js-load-creator-revenue').on('click', function (e) {
    $(this).addClass('avoid-click');
    $(this).find('i').addClass('fa-spin');
    e.preventDefault();
  });

  // Click button revenue analysic
  $('#js-revenue-statistic-creator').on('click', function (e) {
    $(this).addClass('avoid-click');
    $('#js-export-revenue-statistic-creator').prop('disabled', true).addClass('avoid-click');
  });

  // Click button export Excel
  $('#js-export-revenue-statistic-creator').on('click', function (e) {
    let $this = $(this)
    $this.addClass('avoid-click');

    setTimeout(function () {
      $('#js-revenue-statistic-creator').prop('disabled', false);
      $this.removeClass('avoid-click');
    }, 5000)
  });
});
