$(function () {
  $(".js-checkall-rows").on('click', function () {
    $('.js-checkall-rows--item:checkbox').not(this).prop('checked', this.checked);
    shiftSelectMultipleCheckbox();
  });

  function shiftSelectMultipleCheckbox() {
    let $chkboxes = $('.js-checkall-rows--item');
    let lastChecked = null;

    $chkboxes.on('click', function (e) {
      if (!lastChecked) {
        lastChecked = this;
        return;
      }

      if (e.shiftKey) {
        let start = $chkboxes.index(this);
        let end = $chkboxes.index(lastChecked);

        $chkboxes.slice(Math.min(start, end), Math.max(start, end) + 1).prop('checked', lastChecked.checked);
      }

      lastChecked = this;
    });
  };
  shiftSelectMultipleCheckbox();
});
