import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { Vietnamese } from "flatpickr/dist/l10n/vn.js"

$(function () {
  // With Calendar
  const $rangeDate = $('.js-flp-range-date')
  let defaultDate = undefined

  if ($rangeDate.data('defaultDate')) {
    defaultDate = $rangeDate.data('defaultDate').split(',')
  }

  if ($rangeDate.length) {
    flatpickr($rangeDate, {
      locale: Vietnamese,
      mode: "range",
      maxDate: "today",
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "d-m-Y",
      defaultDate: defaultDate,
    });
  }

  const $flpFromMonth = $('.js-flp-from-month');
  const $flpToMonth = $('.js-flp-to-month');
  createFlpMonth($flpFromMonth, $flpFromMonth.data('defaultDate'), $flpFromMonth.data('maxDate'));
  createFlpMonth($flpToMonth, $flpToMonth.data('defaultDate'), $flpToMonth.data('maxDate'));

  // With Month, No Calendar
  function createFlpMonth($monthFlp, defaultMonth, maxDate) {
    if ($monthFlp.length) {
      return flatpickr($monthFlp, {
        locale: Vietnamese,
        defaultDate: defaultMonth,
        altInput: true,
        maxDate: maxDate,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m-d",
            altFormat: "m-Y",
            theme: "light"
          })
        ]
      });
    }
  }
});

