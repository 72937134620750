$(function () {
  // ======= Sidebar Nav =======
  $('.menu_item.has_nested').on('click', function () {
    let $this = $(this);
    let $menu = $(this).find('.menu');

    if ($this.hasClass('current')) {
      let IsHidden = !$this.hasClass('open');

      if (IsHidden) {
        $menu.stop().slideDown();
      } else {
        $menu.stop().slideUp();
      }
    } else {
      if ($this.hasClass('open')) {
        $menu.hide().stop().slideDown().removeClass('open');
      } else {
        $menu.show().stop().slideUp();
      }
    }
  });

  // ======= Sidebar Filter =======
  $('.clear_filters_btn').on('click', function(e){
    e.preventDefault();
    $(this).closest('.filter_form')[0].reset();
    return false;
  });
});
