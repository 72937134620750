// https://dev.to/wanderingsoul/rails-6-webpacker-and-chartjs-2kek
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

$(function () {
  /** TODO: Các tham số data
    data-options: Giá trị của chart
      type: Kiểu chart
      label: Label
      data: Giá trị
      backgroundColor: Màu nền
      borderColor: Màu viền
      borderWidth: Độ dày đường viền
      stack: Nếu chart có 2 line (bar) nằm đè lên nhau thì set `combined`
    data-labels: Label trục X
    data-title: Tiêu đề chart
    data-tooltips: Custom text tooltips
    data-interaction: Hiển thị tooltips giao thoa giữa các point
    data-axis: Trục chính của chart. Default
  **/
  const $chartBarLine = $('#js-chart-barline');

  if (!$chartBarLine.length) return false;

  const ctx = $chartBarLine[0].getContext('2d');
  const chartData = $chartBarLine.data('options');
  const labels = $chartBarLine.data('labels');
  const title = $chartBarLine.data('title');

  let datasets = [];

  // Setting datasets
  $.each(chartData, function (_, i) {
    let d = i.split(":");
    let obj = {
      type: d[0],
      label: d[1],
      data: d[2].split(','),
      backgroundColor: d[3],
      borderColor: d[4],
      borderWidth: d[5] || 1,
      stack: d[6]
    };

    datasets.push(obj)
  });

  // Setting tooltip
  const footer = (tooltipItems) => {
    let tooltips = $('#js-chart-barline').data('tooltips');
    if (tooltips) {
      let total_amount = tooltips[tooltipItems[0].label].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      return `Tổng: ${total_amount} vnđ`;
    }
  };

  // Setting Plugin
  const plugins = {
    title: { display: true, text: title },
    tooltip: { callbacks: { footer: footer } }
  }

  if ($('.js-group-btn-chart-zoom').length) {
    plugins['zoom'] = {
      pan: {
        enabled: true,
        mode: 'xy',
        threshold: 5,
        modifierKey: 'ctrl'
      },
      zoom: {
        mode: 'x',
        drag: {
          enabled: true
        }
      }
    }
  }

  // Register zoom Plugin
  Chart.register(zoomPlugin);

  // Setting Options
  let options = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      y: {
        stacked: true
      }
    },
    plugins: plugins
  }

  if ($('#js-chart-barline').data('interaction') === false) {
    delete options.interaction
  }

  if ($('#js-chart-barline').data('axis')) {
    options.indexAxis = $('#js-chart-barline').data('axis')
  }

  // Init Chartjs
  const globalChart = new Chart(ctx, {
    data: {
      labels: labels,
      datasets: datasets
    },
    options: options
  });

  // Settings zoom chart
  const $chartZoom = $('.js-chart-zoom')
  if ($chartZoom.length) {
    $(document).on('click', '.js-chart-zoom', function () {
      let $this = $(this)
      let type = $this.data('type')

      if (type == 'reset') {
        globalChart.resetZoom();
        return
      }
      if (type == 'zoomIn') {
        let step = parseFloat($this.data('step')) || 1.3
        globalChart.zoom(step);
        return
      }
      if (type == 'zoomOut') {
        let step = parseFloat($this.data('step')) || 0.8
        globalChart.zoom(step);
        return
      }
    });
  }
})
