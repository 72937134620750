import downloadMultiples from "../components/download_multiple.js";

$(function () {
  // Download Multiples
  $('#js-download-multiple').on('click', function (e) {
    let links = function () {
      return $('.js-download-multiple--item:checked').map(function () {
        return $(this).data('url');
      }).get();
    };

    downloadMultiples(links);
    e.preventDefault();
  });

  // CheckAll
  $('.js-checkall-rows--item').on('change', function () {
    if ($(this).is(':checked')) {
      _displayButtonDownloadBatches(true)
    } else {
      _displayButtonDownloadBatches(false)
    }
  });

  $(".js-checkall-rows").on('click', function () {
    if ($('.js-checkall-rows--item:checkbox:checked').length) {
      _displayButtonDownloadBatches(true)
    } else {
      _displayButtonDownloadBatches(false)
    }
  });

  function _displayButtonDownloadBatches(isShow) {
    if (isShow) {
      $('#js-download-multiple').removeClass('invisible')
    } else {
      $('#js-download-multiple').addClass('invisible')
    }
  }
});
