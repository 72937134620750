function downloadMultiple(links) {
  var download_files, urls;

  // links = function () {
  //   return $('.js-download-multiple--item').map(function () {
  //     return $(this).data('url');
  //   }).get();
  // };
  download_files = function (files) {
    var download_next;
    download_next = function (i) {
      var a;
      if (i >= files.length) {
        return;
      }
      a = document.createElement('a');
      a.href = files[i];
      a.target = '_parent';
      (document.body || document.documentElement).appendChild(a);
      if (a.click) {
        a.click();
      } else {
        $(a).click();
      }
      a.parentNode.removeChild(a);
      setTimeout((function () {
        download_next(i + 1);
      }), 1500);
    };
    download_next(0);
  };
  urls = links();
  if (urls.length > 0) {
    return download_files(urls);
  }
}

export default downloadMultiple;
