$(document).on('change', '.js_event_discount_mode', function(e) {
  if ($(this).val() == 0) {
    $('.js_wrapper_mode_range').show();
    $('.js_wrapper_mode_limit').hide();

    $('.js_mode_range').attr("disabled", false);
    $('.js_mode_limit').attr("disabled", "disabled");
  } else {
    $('.js_wrapper_mode_range').hide();
    $('.js_wrapper_mode_limit').show();

    $('.js_mode_range').attr("disabled", "disabled");
    $('.js_mode_limit').attr("disabled", false);
  }
});

$(document).ready(function() {
  changeEventObjectable();
});

$(document).on('change', '.js_event_object_type', function(e) {
  changeEventObjectable();
});

$(document).on('change', '.js_event_is_all', function(e) {
  changeEventObjectable();
});

function changeEventObjectable() {
  if ($('.js_event_is_all').is(":checked")) {
    $('.js_wrapper_playlist').hide();
    $('.js_wrapper_plan_package').hide();

    $('.js_playlist').attr("disabled", "disabled");
    $('.js_plan_package').attr("disabled", "disabled");
  } else {
    if ($('.js_event_object_type').val() == 'Playlist') {
      $('.js_wrapper_playlist').show();
      $('.js_wrapper_plan_package').hide();

      $('.js_playlist').attr("disabled", false);
      $('.js_plan_package').attr("disabled", "disabled");
    } else {
      $('.js_wrapper_playlist').hide();
      $('.js_wrapper_plan_package').show();

      $('.js_playlist').attr("disabled", "disabled");
      $('.js_plan_package').attr("disabled", false);
    }
  }
}
